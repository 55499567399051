exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-reels-downloader-js": () => import("./../../../src/pages/instagram-reels-downloader.js" /* webpackChunkName: "component---src-pages-instagram-reels-downloader-js" */),
  "component---src-pages-instagram-story-downloader-js": () => import("./../../../src/pages/instagram-story-downloader.js" /* webpackChunkName: "component---src-pages-instagram-story-downloader-js" */),
  "component---src-pages-instagram-video-downloader-js": () => import("./../../../src/pages/instagram-video-downloader.js" /* webpackChunkName: "component---src-pages-instagram-video-downloader-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-youtube-shorts-downloader-js": () => import("./../../../src/pages/youtube-shorts-downloader.js" /* webpackChunkName: "component---src-pages-youtube-shorts-downloader-js" */),
  "component---src-pages-youtube-video-downloader-js": () => import("./../../../src/pages/youtube-video-downloader.js" /* webpackChunkName: "component---src-pages-youtube-video-downloader-js" */)
}

